import Link from 'next/link';
import styles from './index.module.less';
import { AppSettings, pageMetadataMap, PageType } from '@/shared/app-common';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { fetchArticleResourceData } from '@/lib/service';
import { useEffect, useState } from 'react';
import { HomeArticleModel } from '@/types/home';
import { debounce } from 'lodash-es';

const GlobalFooter = ({
  show = true,
  className,
  pageType,
}: {
  show?: boolean;
  className?: string;
  pageType: PageType;
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [dataList, setDataList] = useState<HomeArticleModel[]>([]);

  const loadData = debounce(() => {
    fetchArticleResourceData()
      .then((res) => {
        setDataList(res?.data);
      })
      .catch((error) => {
        setDataList([]);
      });
  }, 300);

  useEffect(() => {
    loadData();
  }, []);

  if (!show) {
    return <></>;
  }

  const cls = classNames(styles.footer, className);

  return (
    <footer className={cls}>
      <div className={styles.container}>
        <div className={styles.logoSection}>
          <div
            className={styles.logo}
            onClick={() => {
              router.push(AppSettings.webHost);
            }}
          >
            <div className={styles.logoIcon}>
              <Image
                src={AppSettings.logoPath}
                alt={AppSettings.name}
                loading="lazy"
                width={32}
                height={32}
              />
            </div>
            <h2>Dolphin Radar</h2>
          </div>

          <p className="font-bold">{t('Your Ultimate Instagram Activity Tracker')}</p>

          <div className={styles.socialContainer}>
            <p>{t('Follow us')}</p>
            <div className={styles.socialLinks}>
              <Link href="https://twitter.com/DolphinRadar" target="__block">
                <Image src="/images/layouts/x-link-icon.webp" alt="x" width={32} height={32} />
              </Link>
              <Link href="https://www.youtube.com/@DolphinRadar" target="__block">
                <Image src="/images/layouts/youtube.webp" alt="youtobe" width={32} height={32} />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.linksGrid}>
          <div className={styles.linkColumn}>
            <h3>{t('Product').toLocaleUpperCase()}</h3>
            <ul>
              <li>
                <Link href={`${AppSettings.webHost}/instagram-tracking-example`}>
                  {t('Analytics Sample')}
                </Link>
              </li>
              <li>
                <Link href={`${AppSettings.webHost}/plan`}>{t('Pricing')}</Link>
              </li>
              <li>
                <Link href="https://forms.gle/bkD7BGuTuWhmiseS7" target="_blank">
                  {t('Contact Us')}
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.linkColumn}>
            <h3>{t('Features').toLocaleUpperCase()}</h3>
            <ul>
              {Object.entries(PageType)
                .filter(
                  ([, value]) =>
                    value !== pageType &&
                    ![PageType.SeeSomeoneActivity, PageType.SeeWhatSomeoneLikes].includes(value),
                )
                .map(([key, value]) => {
                  const path = `${AppSettings.webHost}${
                    router.locale && router.locale !== 'en' ? `/${router.locale}` : ''
                  }${value === PageType.Normal ? '' : `/${value}`}`;
                  const pageMetaData = pageMetadataMap[value];
                  const content =
                    value === PageType.Normal
                      ? t('DolphinRadar: Your Ultimate Instagram Activity Tracker')
                      : t(pageMetaData.titleKey).replace('| DolphinRadar', '');
                  return (
                    <li key={key}>
                      <Link href={path}>{content}</Link>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className={styles.linkColumn}>
            <h3>{t('Resources').toLocaleUpperCase()}</h3>
            <ul>
              {dataList?.length > 0 && (
                <li>
                  <Link
                    href={`${AppSettings.blogHost}/instagram-tracking-blog/${dataList[0]?.title_id}`}
                    target="__block"
                  >
                    {t('Changelog')}
                  </Link>
                </li>
              )}
              <li>
                <Link href={`${AppSettings.blogHost}/instagram-tracking-blog`}>{t('blog')}</Link>
              </li>
              <li>
                <Link href={`${AppSettings.webHost}/about`}>{t('About Us')}</Link>
              </li>
              <li>
                <Link href={`${AppSettings.webHost}/instagram-tracking-faq`}>
                  {t('Help Center')}
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.linkColumn}>
            <h3>{t('Toolkit').toLocaleUpperCase()}</h3>
            <ul>
              <li>
                <Link href={`${AppSettings.webHost}/top-instagram`}>
                  {t('Celebrity Influencers')}
                </Link>
              </li>
              <li>
                <Link href={`${AppSettings.webHost}/anonymous-instagram-story-viewer`}>
                  {t('Instagram Story Viewer')}
                </Link>
              </li>
              <li>
                <Link href={`${AppSettings.webHost}/web-viewer-for-instagram`}>
                  {t('Post Viewer for Instagram')}
                </Link>
              </li>
              <li>
                <Link href={`${AppSettings.webHost}/instagram-hashtag-generator`}>
                  {t('Hashtag Generator For Instagram')}
                </Link>
              </li>
              <li>
                <Link href={`${AppSettings.webHost}/instagram-new-follow-tracker`}>
                  {t('Instagram Recent Follower Tracker')}
                </Link>
              </li>
              <li>
                <Link href={`${AppSettings.webHost}/instagram-unfollowers-tracker`}>
                  {t('Instagram Unfollower Tracker')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.bottomSection}>
        <p>All rights reserved. © 2025 Dolphin Radar</p>
        <div className={styles.legalLinks}>
          <Link href={`${AppSettings.webHost}/privacy-policy`}>{t('privacyPolicy')}</Link>
          <Link href={`${AppSettings.webHost}/terms-of-use`}>{t('Terms of Use')}</Link>
        </div>
      </div>
      <div className={styles.bottomSectionDesc}>
        Dolphin Radar is not affiliated with Instagram. We do not host any Instagram content on our
        servers. All rights belong to their respective owners. All Instagram™ logos and trademarks
        displayed on this application are the property of Instagram.
      </div>
    </footer>
  );
};

export default GlobalFooter;
